<!-- 移动端登录 -->
<template>
  <div id="Login">
    <div class="close" @click="backFun">
      <i class="iconfont icon-guanbi"></i>
    </div>
    <div class="logoCont">
      <img src="../../../assets/img/loginLogo.png" alt="" class="logo" />
    </div>
    <div class="middle">
      <div class="login-dialog"></div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="form.username"
          clearable
          name="username"
          :placeholder="$t('m_login.qsryhmsjh')"
        >
          <template #left-icon>
            <i class="iconfont icon-wode-wode"></i>
          </template>
        </van-field>
        <van-field
          v-model="form.password"
          clearable
          :type="passType"
          name="password"
          :placeholder="$t('m_login.qsrmm')"
        >
          <template #left-icon>
            <i class="iconfont icon-mima1"></i>
          </template>
          <template #right-icon>
            <i
              class="iconfont"
              @click="showPassChange"
              :class="[!showPass ? 'icon-yincang' : 'icon-yanjing_xianshi']"
            ></i>
          </template>
        </van-field>
        <div class="forget-pwd">
          <router-link to="/deflutService">{{
            $t("m_login.wjmm")
          }}</router-link>
          <!-- <a href="/userforgotpassword" class="">忘记密码?</a> -->
        </div>
        <div>
          <van-button
            class="login-btn"
            round
            block
            type="info"
            native-type="submit"
            >{{ $t("m_login.ljdl") }}</van-button
          >
        </div>
      </van-form>
    </div>
    <div class="regist">
      <div class="link">
        <router-link to="/mregister">{{ $t("m_login.kszc") }}</router-link>
        <!-- <a href="/register" class=""> 快速注册 </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { loginDo } from "@/api/user";
import { mapActions } from "vuex";
import { Toast } from "vant";
export default {
  name: "",
  data() {
    return {
      passType: "password",
      showPass: false,
      form: {
        username: "",
        password: "",
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    ...mapActions(["SetToken"]),
    backFun() {
      this.$router.go(-1);
    },
    /* 密码显示隐藏 */
    showPassChange() {
      this.showPass = !this.showPass;
      if(this.showPass) {
        this.passType = 'text'
      }else {
        this.passType = 'password'
      }
    },
    onSubmit(values) {
      console.log("submit", values);
      this.loginFun();
    },
    /* 登录 */
    loginFun() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t('common.loading'),
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      loginDo(this.form).then((res) => {
        console.log(res);
        this.SetToken({
          token: res.data.token,
        });
        setTimeout(() => {
          Toast.clear();
          Toast.success(this.$t('m_login.dlcg'));
          this.$router.push("/index");
        }, 1000);
      });
    },
  },
};
</script>
<style scoped lang="less">
#Login {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background: url(../../../assets/img/bg_login.png) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .close {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.32rem;
    height: 0.32rem;
    line-height: 0.32rem;
    margin: 0.28rem;
    z-index: 2;
    background-position: top center;
    background-size: 100%;

    .iconfont {
      color: #fff;
      font-size: 0.5rem;
    }
  }

  .logoCont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0 1rem;
    position: relative;

    .logo {
      width: 3.7rem;
    }
  }

  .middle {
    .van-form {
      width: 6.4rem;
      margin: 0 auto;
      border-radius: 0.2rem;

      .van-cell {
        margin: 0 auto;
        width: 6.4rem;
        height: 1rem;
        background-color: #373430;
        border-radius: 0.5rem;
        margin-bottom: 0.5rem;
        align-items: center;
      }

      .van-cell::after {
        border-bottom: none;
      }

      .forget-pwd {
        font-size: 0.24rem;
        text-align: right;
        margin-top: -0.3rem;
        padding-right: 0.1rem;

        a {
          color: #fff;
        }
      }

      .login-btn {
        width: 100%;
        height: 1rem;
        background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
        border-radius: 0.5rem;
        font-size: 0.36rem;
        text-align: center;
        color: #323232;
        margin-top: 0.8rem;
        line-height: 1rem;
        letter-spacing: 0.05rem;
        border: none;
      }
    }
  }
}

#Login .regist {
  bottom: 0.4rem;
  left: 0;
  width: 100%;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0;

  a {
    color: #ffffff;
    font-size: 0.28rem;
  }
}

.van-field__left-icon {
  .iconfont {
    font-size: 0.4rem;
    color: #ceab77;
  }
}

/deep/.van-field__right-icon {
  padding-top: 5px;

  .iconfont {
    font-size: 0.5rem;
    margin-top: 5px;
  }
}
</style>